import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Review } from './Review'

export interface Props {
  image?: ImageProps
  reviewSource?: string
  reviewText?: string
  services?: string[]
  title?: string
}

export const ServicesVisual = memo(function ServicesVisual({
  image,
  reviewSource,
  reviewText,
  services,
  title,
}: Props) {
  return (
    <Container row tag="section" wrap stretch>
      <LeftSide>
        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}
        {services ? (
          <Services row wrap>
            {services.map((item, index) => (
              <Service key={index}>
                <FadeInUp>
                  {
                    // @ts-ignore
                    item.label
                  }
                </FadeInUp>
              </Service>
            ))}
          </Services>
        ) : null}
      </LeftSide>
      <RightSide>
        {image ? <Image {...image} /> : null}
        <Review source={reviewSource} text={reviewText} />
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  margin: 8.125rem auto 7.5rem;
  position: relative;
  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    z-index: 3;
  }
  &:before {
    width: 2.875rem;
    height: 2.875rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    top: -1.4375rem;
  }
  &:after {
    width: 1.125rem;
    height: 1.125rem;
    background: ${({ theme }) => theme.colors.variants.primaryLight1};
    top: -0.5625rem;
  }

  @media (max-width: 1023px) {
    margin: 5.625rem auto;
  }
`

const LeftSide = styled.div`
  width: 50%;
  background: ${({ theme }) => theme.colors.variants.primaryLight1};
  padding: 5.375rem 8.125vw 6rem;

  @media (max-width: 1023px) {
    width: 100%;
    padding: 3.75rem 1.9375rem;
  }
`

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.125rem;
  font-weight: 400;
  line-height: 1.75rem;

  @media (max-width: 1023px) {
    font-size: 1.875rem;
    line-height: 2.4375rem;
  }
`

const Services = styled(FlexBox)`
  margin-top: 2.75rem;

  > div {
    width: 50%;
  }

  @media (max-width: 1023px) {
    margin-top: 1.875rem;
  }

  @media (max-width: 767px) {
    > div {
      width: 100%;
    }
  }
`

const Service = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.6875rem;
  font-weight: 800;
  letter-spacing: 0.0688rem;
  line-height: 0.875rem;
  margin-top: 1rem;
  text-transform: uppercase;

  > div {
    padding: 0 3.333vw 0 0.875rem;
    position: relative;
    &:before {
      content: '';
      width: 0.375rem;
      height: 0.375rem;
      background: ${({ theme }) => theme.colors.variants.neutralLight4};
      opacity: 0.2;
      position: absolute;
      top: 0.1875rem;
      left: 0;
      transform: rotate(45deg);
    }
  }

  @media (max-width: 767px) {
    > div {
      padding-right: 0;
    }
  }
`

const RightSide = styled.div`
  width: 50%;
  padding: 6.25rem 8.125vw 7.75rem;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding: 5.625rem 1.9375rem;
  }
`
